import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { Badge, Box, Grid, Tooltip } from "@material-ui/core";
import PubSub from 'pubsub-js';
import TicketService from "../../services/Tickets";
import "./style.css";
import { cleanMessageVCard } from "../../utils/cleanMessageVCard";
import connectToSocket from "../../services/socket-io";
import { Skeleton } from "@material-ui/lab";

const TicketListItemOpen = ({ selectedQueueIds = [], selectedTagsIds = [], visibility }) => {
    const history = useHistory();
    const userId = Number(localStorage.getItem("userId"));
    const idGraf = localStorage.getItem("idGraf");
    const { ticketId } = useParams();
    const [tickets, setTickets] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [connectionError, setConnectionError] = useState(false);
    const connectionErrorRef = useRef(connectionError);
    const visibilityRef = useRef(visibility);

    const pageSize = 15;

    const socketRef = useRef(null);

    const fetchOpenTickets = async () => {
        setIsLoading(true);
        try {
            const data = await TicketService.getTicketsByStatus(pageSize, pageNumber, "open", selectedQueueIds, selectedTagsIds, visibility);

            const { tickets: newTickets, hasMore } = data;
            setTickets((prevTickets) => {
                const prevTicketIds = new Set(prevTickets.map(ticket => ticket.id));
                const uniqueNewTickets = newTickets.filter(ticket => !prevTicketIds.has(ticket.id));
                return [...prevTickets, ...uniqueNewTickets];
            });
            setHasMore(hasMore);
        } catch (error) {
            console.error("Erro ao buscar tickets abertos:", error);
        } finally {
            setIsLoading(false);
            setInitialLoading(false);
        }
    };

    useEffect(() => {
        if (hasMore && !isLoading) {
            fetchOpenTickets();
        }
    }, [pageNumber, hasMore, tickets]);

    useEffect(() => {
        visibilityRef.current = visibility;
      }, [visibility]);

    useEffect(() => {
        setTickets([]);
        setPageNumber(1);
        setHasMore(true);
    }, [selectedQueueIds, selectedTagsIds, visibility]);

    const loadMore = () => {
        if (hasMore && !isLoading) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
    };

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 1.0 && hasMore && !isLoading) {
            loadMore();
        }
    };

    const handleSelectTicket = async (ticket) => {
        if (ticket.unreadMessages !== 0) {
            try {
                await TicketService.updateTicket(ticket.id, {
                    unreadMessages: 0,
                    isSelected: true,
                    userId: ticket?.userId
                });
                PubSub.publish("TICKET_ACTION_SELECTED", { ticketId: ticket.id });

                setTickets((prevTickets) => {
                    const updatedTickets = prevTickets.map((t) =>
                        t.id === ticket.id ? { ...t, unreadMessages: 0 } : t
                    );
                    return updatedTickets;
                });
            } catch (error) {
                console.error("Erro ao atualizar o ticket:", error);
            }
        }
        history.push(`/tickets/${ticket.id}`);
    };

    useEffect(() => {
        const handleTicketUpdates = () => {
            const listener = PubSub.subscribe("TICKET_ACTION_UPDATED", async (_, { newStatus, ticketId }) => {

                setTickets((prevTickets) => {
                    let updatedTickets = prevTickets;

                    if (newStatus === "pending" || newStatus === "closed" || newStatus === "deleted") {
                        updatedTickets = updatedTickets.filter((ticket) => ticket.id !== ticketId);
                    }

                    updatedTickets = updatedTickets.filter((ticket) => ticket.userId);
                    return updatedTickets;
                });

                if (newStatus === "transferTicket") {
                    try {
                        const updatedTicket = await TicketService.getTicketById(ticketId);
                        setTickets((prevTickets) => {

                            const existingTicketIndex = prevTickets.findIndex(ticket => ticket.id === Number(ticketId));
                            if (existingTicketIndex !== -1) {
                                const existingTicket = prevTickets[existingTicketIndex];

                                if (existingTicket.lastMessage !== updatedTicket.lastMessage) {

                                    let updatedTickets = prevTickets.filter(ticket => ticket.id !== Number(ticketId));
                                    updatedTickets = [updatedTicket, ...updatedTickets];
                                    updatedTickets = updatedTickets.filter((ticket) => ticket.userId);
                                    return updatedTickets;
                                } else {

                                    let updatedTickets = [...prevTickets];
                                    updatedTickets[existingTicketIndex] = updatedTicket;
                                    updatedTickets = updatedTickets.filter((ticket) => ticket.userId);
                                    return updatedTickets;
                                }
                            } else {

                                let updatedTickets = [updatedTicket, ...prevTickets];
                                updatedTickets = updatedTickets.filter((ticket) => ticket.userId);
                                return updatedTickets;
                            }
                        });
                    } catch (error) {
                        console.error('Erro ao buscar o ticket atualizado:', error);
                    }
                }
            });

            return () => {
                PubSub.unsubscribe(listener);
            };
        };

        const unsubscribe = handleTicketUpdates();
        return () => {
            unsubscribe();
        };
    }, []);

    const setupSocketListeners = () => {
        if (!socketRef.current) return;

        socketRef.current.on('connect', () => {
            console.log('Socket conectado');
            console.log('connectionErrorRef.current:', connectionErrorRef.current);
            if (connectionErrorRef.current) {
                console.log('Conexão restabelecida, atualizando tickets');
                setConnectionError(false);
                setTickets([]);
                setPageNumber(1);
                setHasMore(true);
                fetchOpenTickets();
            }
        });

        socketRef.current.on('disconnect', (reason) => {
            console.log('Socket desconectado:', reason);
            setConnectionError(true);
            connectionErrorRef.current = true;
            console.log('connectionError após disconnect:', connectionErrorRef.current);
        });

        socketRef.current.on('connect_error', (error) => {
            console.log('Erro de conexão:', error);
            setConnectionError(true);
            connectionErrorRef.current = true;
            console.log('connectionError após connect_error:', connectionErrorRef.current);
        });

        socketRef.current.on(`ticket-open-${idGraf}`, (data) => {
            const updatedTicket = data.ticket;
            if (updatedTicket) {

                const ticketUserId = updatedTicket?.userId;
                const currentVisibility = visibilityRef.current;
          
                if (currentVisibility === 'Me' && ticketUserId !== userId) {
                  return;
                }

                if (data.action === "update") {
                    setTickets((prevTickets) => {
                        const existingTicketIndex = prevTickets.findIndex(ticket => ticket.id === updatedTicket.id);
                        if (existingTicketIndex !== -1) {
                            const existingTicket = prevTickets[existingTicketIndex];
                            if (existingTicket.lastMessage !== updatedTicket.lastMessage) {
                                const filteredTickets = prevTickets.filter(ticket => ticket.id !== updatedTicket.id);
                                return [updatedTicket, ...filteredTickets];
                            } else {
                                const updatedTickets = [...prevTickets];
                                updatedTickets[existingTicketIndex] = updatedTicket;
                                return updatedTickets;
                            }
                        } else {
                            return [updatedTicket, ...prevTickets];
                        }
                    });
                } else if (data.action === "new") {
                    setTickets((prevTickets) => {
                        const ticketExists = prevTickets.some(ticket => ticket.id === updatedTicket.id);
                        if (!ticketExists) {
                            return [updatedTicket, ...prevTickets];
                        } else {
                            console.warn(`Ticket duplicado recebido para ID: ${updatedTicket.id}`);
                            return prevTickets;
                        }
                    });
                }
            }
        });
    };

    useEffect(() => {
        socketRef.current = connectToSocket();

        setupSocketListeners();

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        connectionErrorRef.current = connectionError;
    }, [connectionError]);


    return (
        <div onScroll={handleScroll} className="box-tickets">
            {initialLoading ? (
                <Box style={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
                    <Skeleton
                        animation="wave"
                        variant="circle"
                        width={45}
                        height={45}
                        style={{ marginRight: '24px' }}
                    />
                    <div>
                        <Skeleton animation="wave" height={15} width={350} />
                        <Skeleton animation="wave" height={15} width={350} />
                        <Skeleton animation="wave" height={15} width={50} />
                    </div>
                </Box>
            ) : tickets.length > 0 ? (
                tickets.map((ticket) => (
                    <React.Fragment key={ticket.id}>
                        <ListItem
                            dense
                            button
                            onClick={() => handleSelectTicket(ticket)}
                            selected={ticketId && +ticketId === ticket.id}
                        >
                            <Tooltip
                                arrow
                                placement="right"
                                title={ticket?.queue?.name || "Sem departamento"}
                            >
                                <span
                                    style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
                                    className="ticketQueueColor"
                                ></span>
                            </Tooltip>
                            <ListItemAvatar>
                                <Avatar src={ticket?.contact?.profilePicUrl} />
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Grid
                                        container
                                        spacing={4}
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        <Grid item xs={9} style={{ marginBottom: '-10px' }}>
                                            <Typography
                                                noWrap
                                                component="span"
                                                variant="body2"
                                                color="textPrimary"
                                                style={{ fontWeight: "500" }}
                                            >
                                                {ticket?.contact?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} className="box-lastMessage-lastTime">
                                            {ticket.lastMessage && ticket.lastMessageTime && (
                                                <Typography
                                                    className={
                                                        isSameDay(parseISO(ticket.lastMessageTime), new Date())
                                                            ? "lastMessageTime"
                                                            : "lastMessageDate"
                                                    }
                                                    component="span"
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {isSameDay(parseISO(ticket.lastMessageTime), new Date()) ? (
                                                        format(parseISO(ticket.lastMessageTime), "HH:mm")
                                                    ) : (
                                                        format(parseISO(ticket.lastMessageTime), "dd/MM/yyyy")
                                                    )}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                }
                                secondary={
                                    <Grid
                                        container
                                        spacing={4}
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        className={ticket.status === "closed" ? "gridWithCustomSpacing" : ""}
                                    >
                                        <Grid item xs={9}>
                                            <Typography
                                                className={
                                                    ticket.status === "closed"
                                                        ? "contactLastMessageClosed"
                                                        : "contactLastMessage"
                                                }
                                                noWrap
                                                component="span"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {cleanMessageVCard(ticket.lastMessage) || <br />}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Badge
                                                badgeContent={ticket.unreadMessages}
                                                classes={{ badge: "badgeStyle" }}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))
            ) : (
                <div className="no-tickets-div">
                    <span className="no-tickets-title">
                        Não há nada aqui!
                    </span>
                    <p className="no-tickets-text">
                        Não há nenhum ticket em atendimento
                    </p>
                </div>
            )}
        </div>
    );
};

export default TicketListItemOpen;
