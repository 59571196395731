import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import { api } from "../../services/api";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { AuthContext } from "../../context/Auth/AuthContext";

import { Button, Divider ,  makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
    invalidContact: {
        marginRight: '61px',
        padding: '10px',
        fontStyle: 'italic',
        color: '#a7a7a7',
    },
}));

const VcardPreview = ({ contact, numbers }) => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const idGraf = localStorage.getItem("idGraf");
    const [selectedContact, setContact] = useState({
        name: "",
        number: 0,
        profilePicUrl: ""
    });

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                if (numbers) {
                    try {
                        let contactObj = {
                            name: contact,
                            number: numbers.replace(/\D/g, ""),
                            email: "",
                            idGraf
                        }

                        const { data } = await api.post("/contact", contactObj);
                        setContact(data);

                    } catch (err) {
                        toastError(err);
                    }
                }

            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [contact, numbers]);

    const handleNewChat = async () => {
        try {
            const idGraf = localStorage.getItem("idGraf");
            const { data: ticket } = await api.post("/tickets", {
                contactId: selectedContact?.id,
                userId: user.id,
                status: "open",
                idGraf
            });
            history.push(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
    }

    if (!numbers) {
        return <span className={classes.invalidContact}>
                     Contato sem número de whatsapp válido  <FontAwesomeIcon icon={faExclamationCircle} />
               </span>;
    }

    return (
        <>
            <div style={{ minWidth: "250px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Avatar src={selectedContact?.profilePicUrl} />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography
                            style={{ marginTop: "12px", marginLeft: "10px" }}
                            variant="subtitle1"
                            color="primary"
                            gutterBottom
                        >
                            {selectedContact?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Button
                            fullWidth
                            color="primary"
                            onClick={handleNewChat}
                            disabled={!selectedContact.number}
                        >
                            Conversar
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default VcardPreview;
