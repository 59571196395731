import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";


import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PersonIcon from '@material-ui/icons/Person';

import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';


import { TagsFilter } from "../TagsFilter";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import {
  Grid,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Icon from "../Icon";
import { api } from "../../services/api";

import { setGlobalTabOpen, tabGlobalOpen } from '../../globalState';
import PubSub from "pubsub-js";
import TicketListItemOpen from "../TicketListItemOpen";
import TicketListItemPending from "../TicketListItemPending";
import TicketListItemClosed from "../TicketListItemClosed";
import connectToSocket from "../../services/socket-io";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },

  customButton: {
    marginLeft: 5,
  },

  customFontOpen: {
    textTransform: "none",
    fontSize: 15,
    letterSpacing: "0.39998px",
    marginLeft: "2px",
    marginRight: "12px",
  },

  customFontPending: {
    textTransform: "none",
    fontSize: 15,
    letterSpacing: "0.39998px",
    marginLeft: "-5px",
    marginRight: "12px",
  },

  customFontResolved: {
    textTransform: "none",
    fontSize: 15,
    letterSpacing: "0.39998px",
    marginLeft: -110,
  },

  selected: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08) !important",
    },
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 7,
    padding: 4,
    marginRight: theme.spacing(1),
    maxWidth: 630,
    height: 45,
  },

  inputGrid: {
    background: "#F0F2F5",
    padding: 20,
  },

  indicator: {
    backgroundColor: (props) => (props.isResolved ? "#00A825" : "#2196F3"),
    height: "4px",
    borderRadius: '5px 5px 0 0',
    padding: "0 2px",
  },
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const [filterTickets, setFilterTickets] = useState(user.profile === 'admin' || user.canSeeAllTickets ? "All" : "Me");
  const [pendingCount, setPendingCount] = useState(0);

  const [selectedTags, setSelectedTags] = useState([]);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [isSearching, setIsSearching] = useState(false);

  const [showTagsFilter, setShowTagsFilter] = useState(false);
  const [valueOpenCount, setValueOpenCount] = useState(0);
  const [valuePendingCount, setValuePendingCount] = useState(0);
  const [isWithoutQueueSelected, setIsWithoutQueueSelected] = useState(true);
  const idGraf = localStorage.getItem("idGraf");
  const [openQueueSelect, setOpenQueueSelect] = useState(false)

  const showAll = filterTickets === 'All' && (user.profile === 'admin' || user.canSeeAllTickets) ? true : false;
  const userId = user.id;

  const fetchTicketsCount = async () => {
    try {
      const queueIdsQueryParam = selectedQueueIds.join(',');
      const { data } = await api.get(`/getCountTickets/${isWithoutQueueSelected}/${showAll}/${userId}?queueIds=${queueIdsQueryParam}`);
      setValueOpenCount(prev => data.openTicketsCount);
      setValuePendingCount(prev => data.pendingTicketsCount);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTicketsCount();
    if (!isWithoutQueueSelected && selectedQueueIds.length === 0) {
      setPendingCount(0);
    }
  }, [filterTickets, idGraf, isWithoutQueueSelected, selectedQueueIds, user.id]);

  useEffect(() => {
    const eventButtonAction = PubSub.subscribe("TICKET_ACTION_UPDATED", (msg, data) => {
      fetchTicketsCount();
      if ((data.newStatus === "open" || data.newStatus === "transferTicket") && tabGlobalOpen === "pending" && msg) {
        setTabOpen("open")
      }
    });
    return () => {
      PubSub.unsubscribe(eventButtonAction);
    };
  }, []);

  useEffect(() => {
    const socket = connectToSocket();

    socket.on(`ticket-${idGraf}`, (data) => {
      if (data.action === "newTicketIncoming") {
        const newTicket = data.ticket;
        if (newTicket) PubSub.publish("TICKET_ACTION_UPDATED", { newStatus: "newTicketIncoming", ticket: newTicket });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);


  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();
    const currentSearchState = searchedTerm !== "";
    setIsSearching(currentSearchState);
    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      if (
        currentSearchState !==
        (searchInputRef.current.value.toLowerCase() !== "")
      ) {
        return;
      }
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setGlobalTabOpen(newValue);
    setTabOpen(newValue);
  };

  const handleButtonClick = () => {
    setShowTagsFilter((prevState) => !prevState);
  };



  const theme = createTheme({
    typography: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    palette: {
      primary: {
        main: "#2196F3",
      },
      secondary: {
        main: "#0000",
      },
    },
    overrides: {
      MuiBadge: {
        colorPrimary: {
          backgroundColor: "#DCEFFF",
          color: "#2196F3"
        },
        colorSecondary: {
          backgroundColor: "#B0DBFD",
        },
      },
      MuiTab: {
        root: {
          "&$selected": {
            backgroundColor: "",
            color: "#2196F3",
          },
        },
        textColorInherit: {
          color: "#2196F3",
          "&$selected": {
            color: "#2196F3",
          },
        },
      },

      MuiMenuItem: {
        root: {
          "&$selected": {
            backgroundColor: "transparent !important",
          },
          "&$selected:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08) !important",
          },
        },
      },
    },
  });

  const GreenTab = withStyles({
    root: {
      flexGrow: 1,
      color: "#757575",
      "&$selected": {
        color: "#00A825 !important",
      },
    },
    selected: {},
  })(Tab);

  const CustomTabs = (props) => {
    const classes = useStyles(props);
    const tabsClasses = {
      root: classes.tabsRoot,
      indicator: classes.indicator,
    };

    return <Tabs {...props} classes={tabsClasses} />;
  };
  const iconResolvedColor = tabOpen === "closed" ? "#00A825" : "#757575";
  const iconPendingColor =
    tabOpen === "pending" ? theme.palette.primary.main : "#656565";
  const iconOpenColor =
    tabOpen === "open" ? theme.palette.primary.main : "#656565";
  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={0}
        variant="outlined"
        className={classes.ticketsWrapper}
      >
        <NewTicketModal
          modalOpen={newTicketModalOpen}
          onClose={(e) => setNewTicketModalOpen(false)}
        />
        <Grid container className={classes.inputGrid}>
          <Grid item xs>
            <div className={classes.serachInputWrapper}>
              <SearchIcon className={classes.searchIcon} />
              <InputBase
                className={classes.searchInput}
                inputRef={searchInputRef}
                placeholder={i18n.t("tickets.search.placeholder")}
                type="search"
                onChange={handleSearch}
              />
            </div>
          </Grid>
          <Grid item>
            <Tooltip arrow title={i18n.t('ticketsManager.buttons.newTicket')} placement="bottom">
              <IconButton
                className={classes.customButton}
                variant="outlined"
                color="primary"
                onClick={() => setNewTicketModalOpen(true)}
              >
                <Icon type="plusMessage" w={21} h={21} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Paper square elevation={0} className={classes.ticketOptionsBox}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={4} md={4}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Can
                  role={user.profile}
                  perform="tickets-manager:showall"
                  yes={() => (
                    <>
                      <Tooltip arrow title={i18n.t('ticketsManager.filter.myTickets')} placement="top">
                        <IconButton color={filterTickets === "Me" ? "primary" : "default"} onClick={() => setFilterTickets("Me")}>
                          <PersonIcon />
                        </IconButton>
                      </Tooltip>
                      <Divider orientation="vertical" variant="middle" style={{ marginLeft: 1, marginRight: 1 }} flexItem />
                      <Tooltip arrow title={i18n.t('ticketsManager.filter.allTickets')} placement="top">
                        <IconButton color={filterTickets === "All" ? "primary" : "default"}
                          disabled={user.profile === 'admin' || user.canSeeAllTickets ? false : true} onClick={() => setFilterTickets("All")}>
                          <PeopleAltOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      {user.profile === 'admin' || user.canSeeAllTickets ? null : (
                        <Tooltip arrow title={i18n.t('ticketsManager.filter.disabledAllTickets')} placement="bottom"
                        >
                          <InfoOutlinedIcon style={{ fontSize: "small", marginLeft: '-10px', marginTop: '10px', color: 'grey' }} />
                        </Tooltip>
                      )}
                    </>
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={4} md={4}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Tooltip arrow title={i18n.t("mainDrawer.listItems.tags")} placement="top">
                  <IconButton color={showTagsFilter ? 'primary' : 'default'} onClick={handleButtonClick}>
                    <LocalOfferIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>

            <Grid item xs={4} md={4}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <>
                  <Tooltip arrow title={i18n.t("mainDrawer.listItems.departments")} placement="top">
                    <IconButton style={{ marginRight: '-150px' }} onClick={() => setOpenQueueSelect(!openQueueSelect)}>
                      <Icon type="departments" />
                    </IconButton>
                  </Tooltip>
                  <TicketsQueueSelect
                    selectedQueueIds={selectedQueueIds}
                    userQueues={user?.queues}
                    onChange={(values) => setSelectedQueueIds(values)}
                    isWithoutQueueSelected={isWithoutQueueSelected}
                    onIsWithoutQueueSelectedChange={setIsWithoutQueueSelected}
                    open={openQueueSelect}
                    setOpen={setOpenQueueSelect}
                  />
                </>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <TagsFilter onFiltered={handleSelectedTags} show={showTagsFilter} />
        {!isSearching && (
          <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
            <CustomTabs
              value={tabOpen}
              onChange={handleChangeTabOpen}
              textColor="primary"
              variant="fullWidth"
              isResolved={tabOpen === "closed"}
            >
              <Tab
                label={
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Icon type="chatme" mt={5} fill={iconOpenColor} style={{ marginRight: "4px" }} />
                    </Grid>
                    <Grid item>
                      <span className={classes.customFontOpen}>
                        {i18n.t("ticketsList.assignedHeader")}
                      </span>
                      <Badge
                        style={{ marginBottom: "20px" }}
                        badgeContent={valueOpenCount}
                        color="primary"
                        className={classes.badgeOpen}
                      />
                    </Grid>
                  </Grid>
                }
                value={"open"}
              />
              <Tab
                label={
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Icon type="timer" w={21.179} h={21.179} fill={iconPendingColor} mt={5} mr={6} />
                    </Grid>
                    <Grid item>
                      <span className={classes.customFontPending}>
                        {i18n.t("ticketsList.pendingHeader")}
                      </span>
                      <Badge
                        style={{ marginBottom: "15px" }}
                        badgeContent={valuePendingCount}
                        color="primary"
                        className={classes.badgePending}
                      />
                    </Grid>
                  </Grid>
                }
                value={"pending"}
              />
              <GreenTab
                label={
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-around"
                    spacing={10}
                  >
                    <Grid item>
                      <span>
                        <Icon type="check" w={30} h={30} mt={10} fill={iconResolvedColor} />
                      </span>
                    </Grid>
                    <Grid item>
                      <span className={classes.customFontResolved}>
                        {i18n.t("ticketsList.closedHeader")}
                      </span>
                    </Grid>
                  </Grid>
                }
                value={"closed"}
              />
            </CustomTabs>
            <Paper className={classes.ticketsWrapper}>
              {tabOpen === "open" && <TicketListItemOpen selectedQueueIds={selectedQueueIds} selectedTagsIds={selectedTags} visibility={filterTickets} />}
              {tabOpen === "pending" && <TicketListItemPending selectedQueueIds={selectedQueueIds} selectedTagsIds={selectedTags} visibility={filterTickets} />}
              {tabOpen === "closed" && <TicketListItemClosed selectedQueueIds={selectedQueueIds}  selectedTagsIds={selectedTags} visibility={filterTickets} />}
            </Paper>
          </TabPanel>
        )}

        {isSearching && (
          <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
            <Tabs
              value={tabOpen}
              onChange={handleChangeTabOpen}
              textColor="primary"
              variant="fullWidth"
              isResolved={tabOpen === "closed"}
            >
              <Tab
                disabled={true}
                label={
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Icon type="open" fill={"#656565"} />
                    </Grid>
                    <Grid item>
                      <span className={classes.customFontOpen}>
                        {i18n.t("ticketsList.assignedHeader")}
                      </span>
                    </Grid>
                  </Grid>
                }
                value={"open"}
              />
              <Tab
                disabled={true}
                label={
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Icon type="pending" fill={"#656565"} />
                    </Grid>
                    <Grid item>
                      <span className={classes.customFontPending}>
                        {i18n.t("ticketsList.pendingHeader")}
                      </span>
                    </Grid>
                    <Grid item>
                      <Badge
                        badgeContent={pendingCount}
                        color="primary"
                        className={classes.badgePending}
                      />
                    </Grid>
                  </Grid>
                }
                value={"pending"}
              />
              <Tab
                disabled={true}
                label={
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Icon type="closed" fill={"#656565"} />
                    </Grid>
                    <Grid item>
                      <span className={classes.customFontResolved}>
                        {i18n.t("ticketsList.closedHeader")}
                      </span>
                    </Grid>
                  </Grid>
                }
                value={"closed"}
              />
            </Tabs>
            <TicketsList
              selectedTags={selectedTags}
              searchParam={searchParam}
              tabOpen={tabOpen}
              tags={selectedTags}
              showAll={true}
              selectedQueueIds={selectedQueueIds}
              isWithoutQueueSelected={isWithoutQueueSelected}
            />
          </TabPanel>
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default TicketsManager;
