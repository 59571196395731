import React, { useRef, useState, useEffect, useReducer, useContext, useMemo } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import TicketsSkeleton from "../../components/TicketsListSkeleton/index";
import { api } from "../../services/api";
import PubSub from "pubsub-js";
import TicketsSubListComponent from "../TicketsSubList";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    overflowY: "scroll",
    overflowX: "hidden",
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
    marginBottom: "-20px",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_TICKETS": {
      const newTickets = action.payload;
      if (newTickets.length === 0) return state;

      const updatedState = [...state];
      newTickets.forEach((ticket) => {
        const ticketIndex = updatedState.findIndex((t) => t.id === ticket.id);
        if (ticketIndex !== -1) {
          updatedState[ticketIndex] = ticket;
        } else {
          updatedState.push(ticket);
        }
      });
      updatedState.sort((a, b) => new Date(b.lastMessageTime) - new Date(a.lastMessageTime));

      return updatedState;
    }
    case "RESET_UNREAD": {

      const ticketId = action.payload;

      const ticketIndex = state.findIndex((t) => t.id === ticketId);
      if (ticketIndex !== -1) {
        state[ticketIndex].unreadMessages = 0;
      }

      return [...state];
    }

    case "UPDATE_TICKET": {
      const ticket = action.payload;

      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
      } else {
        state.push(ticket);
      }


      const updatedState = state.sort((a, b) => new Date(b.lastMessageTime) - new Date(a.lastMessageTime));

      return [...updatedState];
    }
    case "UPDATE_TICKET_UNREAD_MESSAGES": {
      const ticket = action.payload;

      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        state.unshift(state.splice(ticketIndex, 1)[0]);
      } else {
        state.unshift(ticket);
      }

      return [...state];
    }

    case "UPDATE_TICKET_CONTACT": {
      const contact = action.payload;
      const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
      if (ticketIndex !== -1) {
        state[ticketIndex].contact = contact;
      }
      return [...state];
    }

    case "DELETE_TICKET": {
      const ticketId = action.payload;
      const ticketIndex = state.findIndex((t) => t.id === ticketId);
      if (ticketIndex !== -1) {
        state.splice(ticketIndex, 1);
      }

      return [...state];
    }

    case "RESET": {
      return [];
    }

    default:
      return state;
  }
};

const TicketsList = (props) => {
  const {
    handleTicketAccepted,
    status,
    searchParam,
    showAll,
    selectedQueueIds,
    isWithoutQueueSelected,
    selectedTags,
    style,
    tabOpen,
  } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMoreClosedTickets, setHasMoreClosedTickets] = useState([]);
  const [ticketsClosed, setTicketsClosed] = useState([]);

  const [pageClosed, setPageClosed] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const [allTickets, setAllTickets] = useState([]);
  const { user } = useContext(AuthContext);
  const lastSearchParam = useRef();


  const idGraf = localStorage.getItem("idGraf");
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [status, searchParam, dispatch, showAll, selectedQueueIds, selectedTags]);

  const { tickets, hasMore, loading } = useTickets({
    tabOpen,
    pageNumber,
    searchParam,
    status,
    showAll,
    queueIds: JSON.stringify(selectedQueueIds),
    tags: JSON.stringify(selectedTags),
    isWithoutQueueSelected
  });

  useEffect(() => {
    if (!status && !searchParam) return;
    dispatch({ type: "LOAD_TICKETS", payload: tickets, });
  }, [tickets, status, searchParam, dispatch, tabOpen]);

  useEffect(() => {
    const socket = openSocket();
    const shouldUpdateTicket = (ticket) =>
      (!ticket.userId || ticket.userId === user?.id || showAll) &&
      (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1) 

    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

    socket.on("connect", () => {
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on(`ticket-${idGraf}`, (data) => {
      if (data.action === "reopening") {
        PubSub.publish("TICKET_ACTION_UPDATED", { ticketId: data.ticketId, newStatus: data.action });
      }
      if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
        dispatch({
          type: "UPDATE_TICKET",
          payload: data.ticket,
        });
      }

      if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
      }

      if (data.action === "newTicketIncoming") {
        PubSub.publish("TICKET_ACTION_UPDATED", { newStatus: data.action });
      }

    });

    socket.on(`appMessage-${idGraf}`, (data) => {
      if ((data.action === "create" || data.action === "update") && shouldUpdateTicket(data.ticket)) {
        dispatch({
          type: "UPDATE_TICKET_UNREAD_MESSAGES",
          payload: data.ticket,
        });
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
        setPageNumber(1);
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        dispatch({
          type: "UPDATE_TICKET_CONTACT",
          payload: data.contact,
        });
      }
    });

    socket.on("incomingMessage", (data) => {
      if (data) {
        handleTicketStatusChange(data.ticketId);
      }
    });

    socket.on("downloadedMedia", (data) => {
      if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
        setPageNumber(1);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [status, showAll, user, selectedQueueIds, selectedTags, idGraf]);

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (tabOpen !== "closed") {
      if (!hasMore || loading) return;
      if (scrollHeight - (scrollTop + 100) < clientHeight) {
        e.currentTarget.scrollTop = scrollTop - 100;
        loadMore();
      }
    } else {
      if (!hasMoreClosedTickets) return;
      if (scrollHeight - (scrollTop + 100) < clientHeight) {
        e.currentTarget.scrollTop = scrollTop - 200;
        setPageClosed((prevState) => prevState + 1);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/getClosedTickets?page=${pageClosed}`);
        dispatch({ type: "LOAD_TICKETS", payload: tickets });
        const { ticketsClosed } = response.data;

        if (ticketsClosed.length === 0) {
          setHasMoreClosedTickets(false);
        } else {
          setTicketsClosed(prevTickets => {
            const combinedTickets = [...prevTickets, ...ticketsClosed];
            const uniqueTickets = Array.from(new Map(combinedTickets.map(ticket => [ticket.id, ticket])).values());
            return uniqueTickets;
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    if (tabOpen === "closed" && !searchParam) {
      fetchData();
    } else {
      setPageClosed(1);
      setTicketsClosed([]);
    }

  }, [tabOpen, handleTicketAccepted, pageClosed, idGraf, searchParam, tickets]);

  const handleTicketStatusChange = (ticketId) => {
    setTicketsClosed((currentTickets) =>
      currentTickets.filter((ticket) => ticket.id !== ticketId)
    );
  };

  useEffect(() => {
    const eventButtonAction = PubSub.subscribe("TICKET_ACTION_UPDATED", (msg, data) => {
      if (data.newStatus === "closed" && msg) {
        setTicketsClosed(data.ticketsClosed);
      }
      if (data.newStatus === 'reopening') {
        setTicketsClosed(currentTickets =>
          currentTickets.filter(ticket => ticket.id !== data.ticketId)
        );

      }
    });
    return () => {
      PubSub.unsubscribe(eventButtonAction);
    };
  }, [ticketsClosed]);

  useEffect(() => {
    const searchChanged = lastSearchParam.current !== searchParam;

    const updateTickets = (newTickets) => {
      setAllTickets(prevTickets => {
        const updatedTickets = searchChanged ? [] : [...prevTickets];

        if (!searchChanged) {
          newTickets.forEach(newTicket => {
            const ticketIndex = updatedTickets.findIndex(ticket => ticket.id === newTicket.id);
            if (ticketIndex !== -1) {
              updatedTickets[ticketIndex] = newTicket;
            } else {
              updatedTickets.push(newTicket);
            }
          });
        }

        updatedTickets.sort((a, b) => new Date(b.lastMessageTime) - new Date(a.lastMessageTime));
        return updatedTickets;
      });
    };

    updateTickets(searchChanged ? [] : tickets);

    lastSearchParam.current = searchParam;
  }, [searchParam, tickets]);

  useEffect(() => {
    const eventButtonAction = PubSub.subscribe("TICKET_ACTION_SELECTED", (msg, data) => {
      if (data.ticketId) {
        dispatch({ type: "RESET_UNREAD", payload: data.ticketId });
      }
    });

    return () => {
      PubSub.unsubscribe(eventButtonAction);
    };
  }, [dispatch]);

  const { filteredChatTickets, filteredMessageTickets, filteredGroupTickets } = useMemo(() => {
    const filteredChatTickets = allTickets.filter(ticket =>
      !ticket.isGroup && ticket.isChat
    );

    const filteredMessageTickets = allTickets.filter(ticket =>
      !ticket.isChat
    );

    const filteredGroupTickets = allTickets.filter(ticket =>
      ticket.isGroup
    );

    return { filteredChatTickets, filteredMessageTickets, filteredGroupTickets };
  }, [allTickets]);

  return (
    <Paper className={classes.ticketsListWrapper} style={style} key={tabOpen}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0 }}>
          {tabOpen !== "closed" && !loading && ticketsList.length === 0 &&
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage", {
                  skipCapitalization: true,
                })}
              </p>
            </div>
          }
            <>
              {filteredChatTickets.length > 0 && (
                <TicketsSubListComponent
                  tickets={filteredChatTickets}
                  titleKey="ticketsList.chats"
                  idGraf={idGraf}
                  loading={loading}
                  handleTicketStatusChange={handleTicketStatusChange}
                />
              )}
              {filteredGroupTickets.length > 0 && (
                <TicketsSubListComponent
                  tickets={filteredGroupTickets}
                  titleKey="ticketsList.groups"
                  idGraf={idGraf}
                  loading={loading}
                  handleTicketStatusChange={handleTicketStatusChange}
                />
              )}
              {filteredMessageTickets.length > 0 && (
                <TicketsSubListComponent
                  tickets={filteredMessageTickets}
                  titleKey="ticketsList.messages"
                  idGraf={idGraf}
                  loading={loading}
                  handleTicketStatusChange={handleTicketStatusChange}
                />
              )}
              {loading && allTickets.length === 0 && <TicketsSkeleton />}
            </>
        </List>
      </Paper>
    </Paper>
  );
};

export default TicketsList;
